@import './index.css';
@media (max-width: 300px) {
    .toast-message-container {
      width: 200px !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
    }
  }
  
  @media (max-width: 500px) {
    .toast-message-container {
      width: 300px !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
    }
  }
  
  
  @media (min-width: 500px) {
    .toast-message-container {
      width: 600px !important;
    }
  }
  
  .toast-message-container .Toastify__toast {
    margin-bottom: 0px;
    padding: 11px;
    border-radius: 0px;
    align-items: center;
    box-shadow: none;
  }
  
  .Toastify__toast-container.toast-message-container {
    z-index: 100000 !important;
  }
  
  .Toastify__toast-theme--light {
    background-color: #FFF;
  }
  
  /* ERROR */
  .Toastify__toast.toast-error {
    border-left: 2px solid #ff0101;
    background: #fff !important;
    color: #ff0101;
  }
  
  .Toastify__toast.toast-error::before {
    content: url("/src/assets/close-circle.svg");
    position: relative;
    z-index: 100000;
    left: 12px;
    top: 6px;
    margin-right: 8px
  }
  
  /* SUCCESS */
  
  .Toastify__toast.toast-success {
    border-left: 2px solid #0EC17E;
    background: #fff !important;
    color: #0b8800;
  }
  
  .Toastify__toast.toast-success::before {
    content: url("/src/assets/tick-circle.svg");
    position: relative;
    z-index: 100000;
    left: 12px;
    top: 6px;
    margin-right: 8px
  }
  
  
 
  /* WARNING */
  .Toastify__toast.toast-message-container--warning {
    border: 1px solid #f1daac;
    background: none !important;
  }
  
  .Toastify__toast.toast-message-container--warning::after {
    content: url("/src/assets/tick-circle.svg");
    position: absolute;
    left: 265px;
  }
  
  .Toastify__toast.toast-message-container--warning::before {
    content: url("/src/assets/tick-circle.svg");
    position: relative;
    z-index: 100000;
    left: 12px;
    top: 6px;
  }
  
  .Toastify__toast.toast-message-container--warning .Toastify__toast-body {
    color: #efac01;
    margin-right: 22px;
  }
  
  /* INFORMATION */
  .Toastify__toast.toast-message-container--info {
    background: #f3f6ff !important;
    border: 1px solid #accdf1;
  }
  
  .Toastify__toast.toast-message-container--info::after {
    content: url("/src/assets/tick-circle.svg");
    position: absolute;
    left: 265px;
  }
  
  .Toastify__toast.toast-message-container--info::before {
    content: url("/src/assets/tick-circle.svg");
    position: relative;
    z-index: 100000;
    left: 12px;
    top: 6px;
  }
  
  .Toastify__toast.toast-message-container--info .Toastify__toast-body {
    color: #0068db;
    margin-right: 22px;
  }
  .Toastify__progress-bar--bg{
    display: none !important;
  }

  .Toastify__toast-body {
    font-size: 16px;
    line-height: normal;
    width: 100%;
    font-weight: 500;
    padding: 0px;
    margin: 0px;
    margin-left: 20px !important;
  }
  
  .Toastify__toast>button>svg {
    display: none;
  }
  
  .Toastify__toast-icon {
    display: none !important;
  }
  
  .Toastify__progress-bar {
    visibility: hidden;
  }
  
  /* PAGINATION */
  .pagination {
    margin-top: 20px;
  }
  
  .pagination ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .pagination ul li {
    display: inline-block;
    margin-right: 5px;
    cursor: pointer;
  }
  
  .pagination ul li.active {
    font-weight: bold;
  }
  .ant-modal-footer{
    width: 50%;
    gap: 10px;
    margin-left: auto;
    margin-right: auto;
    /* background:#000  !important; */
    border: 2px !important;
    border-color: #0979A1 !important;
  }
  .ant-btn-primary{
    background-color: #0979A1 !important;
    width: 45%;
  }
  .ant-btn-primary:hover{
    background-color: #0cb3f0 !important;
    width: 45%;
  }

 .ant-btn-default{
  background-color: #A10909 !important;
  color: white;
  width: 45%;
 }

 .ant-btn-default:hover{
  background-color: #c00606 !important;
  width: 45%;
  color: #fff !important;
 }

 .custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
}

.custom-prev {
  left: -25px;
}

.custom-next {
  right: -25px;
}
aside::-webkit-scrollbar {
  width: 8px;
}

aside::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2); 
  border-radius: 4px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px; 
}
aside::-webkit-scrollbar-track {
  background: transparent; 
  border-radius: 8px;
}

aside {
  scrollbar-width: thin; 
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  scrollbar-corner-radius: 8px; 
}

aside {
  --scrollbar-thumb-color: rgba(0, 0, 0, 0.2); 
}

aside::-moz-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
  border-radius: 8px; 
}

